import React from 'react';
import styled from '../styled';
import { Twitter } from 'react-feather';

const IconWrapper = styled('div')`
  position: absolute;
  top: ${(p) => p.theme.spacing(4)}px;
  right: ${(p) => p.theme.spacing(3)}px;
  color: ${(p) => p.theme.palette.grey[500]};
`;

const TweetWrapper = styled('div')`
  position: relative;
  padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(3)}px;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
  background: ${(p) => p.theme.palette.grey[800]};
  border: 1px solid ${(p) => p.theme.palette.grey[900]};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(1)}px;
    margin-bottom: ${(p) => p.theme.spacing(2)}px;
  }
`;

const AuthorWrapper = styled('div')`
  display: flex;
`;

const AuthorImage = styled('img')`
  width: 48px;
  height: 48px;
  margin-right: 12px;
  border-radius: 100%;

  @media (max-width: 800px) {
    width: 36px;
    height: 36px;
    margin-right: 6px;
  }
`;

const AuthorName = styled('div')`
  display: block;
  font-weight: 700;
`;

const TwitterHandle = styled('a')`
  display: block;
  font-size: ${(p) => p.theme.typography.body2.fontSize};
  color: ${(p) => p.theme.palette.grey[400]};
`;

const Content = styled('p')`
  margin-bottom: 0;
  color: ${(p) => p.theme.palette.grey[200]};

  a {
    color: ${(p) => p.theme.palette.primary.dark};
  }
`;

export const Tweet = ({
  name,
  username,
  content,
  image,
  url
}: {
  name: string;
  username: string;
  content: React.ReactNode;
  image: string;
  url: string;
}) => {
  return (
    <TweetWrapper>
      <IconWrapper>
        <a href={url} target="_blank" rel="nofollow noopener">
          <Twitter size={24} />
        </a>
      </IconWrapper>
      <AuthorWrapper>
        <div>
          <AuthorImage src={image} alt={name} />
        </div>
        <div>
          <AuthorName>{name}</AuthorName>{' '}
          <TwitterHandle>@{username}</TwitterHandle>
        </div>
      </AuthorWrapper>
      <div>
        <Content>{content}</Content>
      </div>
    </TweetWrapper>
  );
};
