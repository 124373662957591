import React, { useState } from 'react';
import { sortBy } from 'lodash';
import { Link } from 'gatsby';
import { Typography, Button, Paper } from '@material-ui/core';
import firebase from 'firebase/app';
import Layout from '../layouts/Layout';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { Highlight, HighlightWhite } from '../components/Highlight';
import { SignupOrLogin, SignupOrLoginDialog } from '../components/LoginForm';
import { ExitIntentPopup } from '../components/ExitIntentPopup';
import { FaqGroup } from '../components/Faq';
import { Author } from '../components/ProAuthor';
import { InnerForm } from '../components/SubscriptionForm';
import { SUBSCRIBER_COUNT } from '../constants';
import { InfoMessage } from '../components/Alerts';
import { Tweet } from '../components/Tweet';
import { onlyOnClientSide } from '../components/ClientSidePage';
import { useMappedLoadingValue } from '../services/db';
import { useEvents } from '../services/events';
import { Doc } from '../types/Document';
import { IEvent } from '../types/Event';
import { MiniEvent } from '../components/Event';

const TestimonialsOverflow = styled('div')`
  height: 160px;
  overflow-x: scroll;
`;

const SignupWrapper = styled(Paper)`
  padding: 32px 40px;
  max-width: 840px;
  margin: 0 auto;

  @media (max-width: 800px) {
    padding: 18px 20px;
  }
`;

const Title = styled('h1')`
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: ${(p) => p.theme.palette.grey[100]};
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 70px;

  @media (max-width: 700px) {
    font-size: 28px;
    line-height: 36px;
    padding: 0 24px;
  }
`;

const Copy = styled('p')`
  color: ${(p) => p.theme.palette.grey[100]};
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  max-width: 624px;
  margin: 0 auto 28px;
  padding: 0 12px;

  @media (max-width: 700px) {
    font-size: 16px;
    line-height: 32px;
    padding: 0 24px;
  }
`;

const CopyBig = styled('p')`
  color: ${(p) => p.theme.palette.grey[100]};
  font-weight: 500;
  max-width: 624px;
  font-size: 24px;
  line-height: 42px;
  margin: 0 auto 28px;
  padding: 0 12px;

  @media (max-width: 700px) {
    font-size: 20px;
    line-height: 38px;
    padding: 0 24px;
  }
`;

const PrimarySubheading = styled<'h2', { color: 'white' | 'blue' }>('h2')`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  color: ${(p) =>
    p.color === 'white' ? 'white' : p.theme.palette.primary.dark};
  margin-bottom: 24px;

  @media (max-width: 700px) {
    padding: 0 24px;
  }
`;

const SecondarySubheading = styled('p')`
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
  font-style: italic;
  margin-bottom: 48px;
  max-width: 500px;

  @media (max-width: 700px) {
    margin-bottom: 24px;
  }
`;

const LightStripe = styled('div')`
  width: calc(100% + 74px);
  padding: 36px;
  position: relative;
  top: -24px;
  left: -36px;
  background-color: #1890ff;
  transform: rotate(-3deg);
  z-index: -1;
`;

const LightStripeContent = styled('div')`
  max-width: 960px;
  width: 100%;
  margin: 36px auto 16px;
  padding: 24px 24px 0;
  transform: rotate(3deg);
  color: ${(p) => p.theme.palette.grey[100]};
`;

const PlusListColumns = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: 0;
  }
`;

const Stripe = styled('div')`
  width: calc(100% + 74px);
  padding: 36px;
  position: relative;
  left: -36px;
  background: linear-gradient(180deg, #1890ff 0%, #0050b3 100%);
  transform: rotate(-3deg);
`;

const StripeContent = styled('div')`
  max-width: 960px;
  width: 100%;
  margin: 36px auto;
  padding: 0 24px;
  transform: rotate(3deg);
`;

const StripeContentColumns = styled('div')`
  max-width: 1200px;
  width: 100%;
  margin: 36px auto;
  padding: 0 24px;
  transform: rotate(3deg);
  display: grid;
  grid-template-columns: 440px 1fr;
  grid-column-gap: 55px;
  padding-top: 48px;
  align-items: center;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 24px;
  }
`;

const StripeHeroImgCover = styled('img')`
  max-width: 100%;
  margin-bottom: 36px;

  @media (max-width: 800px) {
    display: none;
  }
`;

const StripeHeroImg = styled('img')`
  max-width: 100%;
  margin-bottom: 36px;
`;

const CopyWrapper = styled('div')`
  max-width: 624px;
  margin: 48px auto;
  padding: 0 12px;
`;

const DarkBadge = styled('div')`
  font-size: 8px;
  padding: 4px 8px;
  background-color: ${(p) => p.theme.palette.grey[900]};
  border-radius: 4px;
  font-weight: 900;
  text-transform: uppercase;
`;

const Badge = styled('div')`
  font-size: 8px;
  padding: 4px 8px;
  background-color: ${(p) => p.theme.palette.primary.dark};
  border-radius: 4px;
  font-weight: 900;
  text-transform: uppercase;
`;

const ComingSoonBadge = styled('div')`
  display: inline-block;
  font-size: 10px;
  padding: 8px 10px;
  color: ${(p) => p.theme.palette.primary.light};
  background-color: #0050b3;
  border-radius: 4px;
  font-weight: 900;
  text-transform: uppercase;
`;

const ColumnWrapper = styled('div')`
  display: grid;
  grid-template-columns: 252px 252px 252px;
  grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  justify-content: space-between;
  max-width: 900px;
  margin: 72px auto 36px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin: 12px auto 36px;
    padding: ${(p) => p.theme.spacing(1)}px;
  }
`;

const Column = styled('div')`
  h3 {
    color: ${(p) => p.theme.palette.primary.main};
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }

  p {
    font-size: 1rem;
    line-height: 28px;
    text-align: justify;
  }

  img {
    max-width: 100%;
  }

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const CodeOfConductWrapper = styled('div')`
  padding: 48px 0 0;
  margin: 48px auto 0;
  max-width: 900px;

  @media (max-width: 700px) {
    padding: 0;
    margin: 48px auto;
  }
`;

const MemberContentWrapperInner = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(6)}px;
  color: ${(p) => p.theme.palette.grey[300]};
  align-items: center;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 48px 24px;
  }
`;

const MemberContentWrapper = styled('div')`
  max-width: 900px;
  margin: 48px auto;
  padding: 0 0 48px;

  img {
    max-width: 100%;
  }

  h3 {
    color: ${(p) => p.theme.palette.primary.dark};
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    font-style: italic;
  }

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(1)}px;
    margin: 0 auto;
  }
`;

const MiniSubheading = styled('h4')`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white;
  margin-top: 24px;
  margin-bottom: 36px;
`;

const PlusList = styled('ul')`
  padding: 0;
  margin: 0;
  color: inherit;
  z-index: 0;

  li {
    list-style-type: none;
    margin-left: 24px;
    padding-left: 12px;
    margin-bottom: 32px;
    position: relative;
    font-size: 16px;
    line-height: 30px;

    strong {
      color: ${(p) => p.theme.palette.common.white};
      background-color: rgba(255, 255, 255, 0.2);
      padding: 5px;
      border-radius: 3px;
    }

    &:before {
      position: absolute;
      top: 2px;
      left: -24px;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      font-weight: 900;
      color: #1890ff;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      content: '+';
    }
  }
`;

const FindInsideWrapper = styled('div')`
  max-width: 900px;
  margin: 0 auto;
  padding: 24px 24px 0;

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(1)}px;
  }
`;

const TestimonialsWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  max-width: 1200px;
  margin: 0 auto 48px;
  padding-bottom: 24px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 0 12px;
  }
`;

const FinalButton = styled(Button)`
  background: linear-gradient(86.61deg, #f4faff 23.95%, #dcefff 97.88%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: ${(p) => p.theme.palette.grey[800]};
  font-weight: 600;
  padding: 12px 24px;
`;

const FinalCall = styled('div')`
  background: linear-gradient(130.46deg, #0050b3 4.34%, #1890ff 99.39%);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(4)}px
    ${(p) => p.theme.spacing(4)}px;
  position: relative;
  max-width: 680px;
  margin: 0 auto 36px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 800px) {
    display: block;
    margin: 12px;

    div {
      width: 100% !important;
    }
  }

  img {
    position: absolute;
    top: 78px;
    width: 252px;
    left: -54px;

    @media (max-width: 800px) {
      display: none;
    }
  }

  div {
    width: calc(100% - 200px);
  }

  p {
    font-size: 18px;
    line-height: 28px;
    color: ${(p) => p.theme.palette.grey[100]};
    margin-bottom: 24px;
  }
`;

const Quote = styled('div')`
  background: linear-gradient(130.46deg, #0050b3 4.34%, #1890ff 99.39%);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(4)}px
    ${(p) => p.theme.spacing(4)}px;
  position: relative;
  z-index: 1;
  margin-bottom: 36px;

  @media (max-width: 700px) {
    padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(3)}px
      ${(p) => p.theme.spacing(3)}px;
  }

  &:after {
    content: '“';
    color: #191c20;
    font-size: 200px;
    font-family: Georgia, Serif;
    position: absolute;
    z-index: -1;
    top: -68px;
    left: 23px;
  }

  p {
    z-index: 1;
    font-size: 18px;
    line-height: 32px;
    color: ${(p) => p.theme.palette.grey[100]};
    margin-bottom: 24px;

    @media (max-width: 700px) {
      font-size: 16px;
      line-height: 30px;
    }
  }
`;

const FitContainer = styled('div')`
  padding: 24px 36px;
  border: 3px solid ${(p) => p.theme.palette.primary.main};
  border-radius: 8px;

  @media (max-width: 800px) {
    padding: 18px 20px;
  }
`;

const IsItAFit = styled('div')`
  display: grid;
  grid-template-columns: 55% 45%;
  grid-column-gap: 48px;
  align-items: center;
  max-width: 900px;
  margin: 48px auto;
  padding: 48px 0;

  h3 {
    color: ${(p) => p.theme.palette.primary.dark};
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    font-style: italic;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 24px 12px;
    margin: 12px auto;
  }
`;

const CheckList = styled('ul')`
  padding: 0;
  margin: 24px 0 0;
  color: ${(p) => p.theme.palette.grey[200]};

  strong {
    color: white;
    font-weight: 900;
  }

  li {
    list-style-type: none;
    margin-left: 24px;
    padding-left: 12px;
    margin-bottom: 32px;
    position: relative;
    font-size: 18px;
    line-height: 32px;

    @media (max-width: 700px) {
      font-size: 16px;
      line-height: 30px;
    }

    &:before {
      position: absolute;
      font-family: system-ui;
      top: 4px;
      left: -24px;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      font-weight: 900;
      background-color: #0050b3;
      color: ${(p) => p.theme.palette.primary.main};
      display: flex;
      align-items: center;
      justify-content: center;
      content: '✓';
    }
  }
`;

const CrossList = styled('ul')`
  padding: 0;
  margin: 24px 0 0;
  color: ${(p) => p.theme.palette.grey[400]};

  strong {
    color: ${(p) => p.theme.palette.grey[300]};
    font-weight: 900;
  }

  li {
    list-style-type: none;
    margin-left: 24px;
    padding-left: 12px;
    margin-bottom: 32px;
    position: relative;
    font-size: 16px;
    line-height: 24px;

    &:before {
      position: absolute;
      font-family: system-ui;
      top: 2px;
      left: -24px;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: ${(p) => p.theme.palette.grey[900]};
      color: ${(p) => p.theme.palette.grey[500]};
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      content: '✖';
    }
  }
`;

const FaqWrapper = styled('div')`
  margin: ${(p) => p.theme.spacing(4)}px auto;
  max-width: 800px;

  @media (max-width: 800px) {
    margin: 0 auto;
    padding: 12px;
  }
`;

const Tweets = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  max-width: 1200px;
  margin: 48px auto;
  padding: 0 24px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 0 ${(p) => p.theme.spacing(2)}px;
    grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  }
`;

const TweetWall = () => {
  return (
    <div>
      <PrimarySubheading color="blue">
        What members are saying
      </PrimarySubheading>
      <SecondarySubheading>
        From improving as a writer and blogger, learning SEO, finding
        partnerships, and making friends.
      </SecondarySubheading>
      <Tweets>
        <div>
          <Tweet
            name="Josh Comeau"
            username="JoshWComeau"
            image="/images/josh-small.jpeg"
            url="https://twitter.com/JoshWComeau/status/1398369530024497159"
            content={
              <>
                I highly recommend the BFD community. Some amazing devs +
                bloggers hang out there, and I learn a ton every time I swing
                by.
              </>
            }
          />
          <Tweet
            name="Alyssa Holland"
            username="Code_Runnings"
            image="/images/alyssa-small.jpg"
            url="https://twitter.com/Code_Runnings"
            content={
              <>
                Everyone in the community is very welcoming and supportive!
                <br />
                <br />
                Plus, having access to the community resources that help me grow
                as a blogger has been priceless so far.
                <br />
                <br />
              </>
            }
          />
          <Tweet
            name="Chris Bongers"
            username="DailyDevTips1"
            image="/images/chris-small.jpg"
            url="https://twitter.com/DailyDevTips1/status/1346201801306075138"
            content={
              <>
                I’ve been a starting member for the{' '}
                <a href="https://twitter.com/bloggingfordevs" target="_blank">
                  @bloggingfordevs
                </a>{' '}
                community.
                <br />
                <br />
                Not only did it help me become a better blogger, I’ve made
                genuine friends and even got some partnerships through the
                course.
              </>
            }
          />
        </div>
        <div>
          <Tweet
            name="Andrea Bizzotto"
            username="biz84"
            image="/images/andrea-small.png"
            url="https://twitter.com/biz84/status/1397887559397695489"
            content={
              <>
                If there is one person who helped me make 100s if not 1000s of
                extra $$ in sales, that is{' '}
                <a href="https://twitter.com/monicalent" target="_blank">
                  @monicalent
                </a>
                !
                <br />
                <br />
                Her advice and feedback about marketing, landing pages, SEO &
                much more had a huge impact on my site.
                <br />
                <br />
                Getting a{' '}
                <a href="https://twitter.com/bloggingfordevs" target="_blank">
                  @bloggingfordevs
                </a>{' '}
                Pro membership has paid off many times over!
              </>
            }
          />
          <Tweet
            name="Abraham Orenalles"
            username="abe_orellanes"
            image="/images/abraham-small.jpg"
            url="https://twitter.com/abe_orellanes/status/1362103608158879746"
            content={
              <>
                Blogging for Devs by{' '}
                <a href="https://twitter.com/monicalent" target="_blank">
                  @monicalent
                </a>{' '}
                is the benchmark I measure other communities by.
                <br />
                <br />
                Great content, deals, sub-groups for different interests, weekly
                emails to keep up with all the action...
                <br />
                <br />
                Easily the most well run community I'm a part of.
                <br />
              </>
            }
          />
          <Tweet
            name="Bhanu Teja"
            username="pbteja1998"
            image="/images/bhanu-small.jpg"
            url="https://twitter.com/pbteja1998/status/1356930994989723648"
            content={
              <>
                BFD Community is awesome.
                <br />
                <br />
                Although, I am just passively lurking, I gain so much value out
                of it. Thanks for building this community 🙏
                <br />
                <br />
              </>
            }
          />
        </div>
        <div>
          <Tweet
            name="Michael Lynch"
            username="deliberatecoder"
            image="/images/michael-small.jpeg"
            url="https://twitter.com/deliberatecoder/status/1370115849885999107"
            content={
              <>
                If you're a developer who blogs (or wants to start), the
                community is a great place to get feedback on your writing and
                learn from other writers.
                <br />
                <br />
                I've gotten a ton out of the community, and I'm continually
                impressed at what{' '}
                <a href="https://twitter.com/monicalent" target="_blank">
                  @monicalent
                </a>{' '}
                has built.
              </>
            }
          />
          <Tweet
            name="Sean Connolly"
            username="seanconnollydev"
            image="/images/seanc-small.jpeg"
            url="https://twitter.com/seanconnollydev/status/1340844415561035776"
            content={
              <>
                Since joining{' '}
                <a href="https://twitter.com/bloggingfordevs" target="_blank">
                  @bloggingfordevs
                </a>{' '}
                in November:
                <br />
                <br />
                🎨 Rebranded my blog
                <br />
                📈 Went from 0-4 visits/day to 20-40
                <br />
                💰 Made $700 from blogging
                <br />
                👨🏻‍🎓 Met many great people and learned a ton
                <br />
                <br />
                That's just in less than 2 months. Looking forward to what 2021
                brings.
              </>
            }
          />
          <Tweet
            name="Rachel Leggett"
            username="rleggos"
            image="/images/rachel-small.jpg"
            url="https://twitter.com/rleggos/status/1323338507692220418"
            content={
              <>
                I'm a member of this community and I love it! Definitely check
                out the website to see if it might be a fit for you!
              </>
            }
          />
        </div>
      </Tweets>
    </div>
  );
};

const WhatMembersAreSaying = () => {
  return (
    <>
      <PrimarySubheading color="blue">
        A word from our members
      </PrimarySubheading>
      <SecondarySubheading>
        When asked, "What's your community experience been like so far?"
      </SecondarySubheading>
      <TestimonialsWrapper>
        <div>
          <Quote>
            <p>
              This community is a wealth of information. I can find topics for
              my blog posts,{' '}
              <HighlightWhite>get feedback from peers</HighlightWhite> and help
              improving my SEO. Monica curates the content and keeps everyone
              engaged.
            </p>
            <br />
            <Author
              color="blue"
              name="	Princiya Sequeira"
              byline="Head of Frontend Engineering & Blogger at Princiya.com"
              img="/images/princiya-small.jpg"
              badge={<DarkBadge>Pro Member</DarkBadge>}
            />
          </Quote>
          <Quote>
            <p>
              Everyone in the community is very welcoming and supportive! Plus,
              having{' '}
              <HighlightWhite>access to the community resources</HighlightWhite>{' '}
              that help me grow as a blogger has been priceless so far.
            </p>
            <Author
              color="blue"
              name="Alyssa Holland"
              byline="UI Developer & Blogger at AlyssaHolland.com"
              img="/images/alyssa-small.jpg"
              badge={<DarkBadge>Pro Member</DarkBadge>}
            />
          </Quote>
        </div>
        <div>
          <Quote>
            <p>
              Thanks to this community,{' '}
              <HighlightWhite>
                I feel like I always have somewhere to go
              </HighlightWhite>{' '}
              if I have a question or am struggling with some aspect of my blog
              or newsletter.
            </p>
            <p>
              I feel confident launching my current project knowing that I've
              been able to get advice from experienced bloggers.
            </p>
            <Author
              color="blue"
              name="Rachel Leggett"
              byline="Frontend Developer & Blogger at Dev Yarns"
              img="/images/rachel-small.jpg"
              badge={<DarkBadge>Pro Member</DarkBadge>}
            />
          </Quote>
          <Quote>
            <p>
              I’ve learned a lot about SEO thanks to Monica. Her advice
              influences how I think about writing my content. I’m using her
              tips to <HighlightWhite>become more strategic</HighlightWhite>{' '}
              about selling my digital products.
            </p>
            <Author
              color="blue"
              name="Joe Previte"
              byline="Developer Advocate & Creator of Vim for VS Code"
              img="/images/joe-small.png"
              badge={<DarkBadge>Pro Member</DarkBadge>}
            />
          </Quote>
        </div>
        <div>
          <Quote>
            <p>
              This community has shown me that{' '}
              <HighlightWhite>any developer can write well</HighlightWhite> if
              they pay attention to the right things.
            </p>
            <p>
              Monica has been a guide and mentor, and sparked my enthusiasm for
              getting intentional with my writing.
            </p>
            <Author
              color="blue"
              name="Rey van den Berg"
              byline="Freelance Web Developer & Blogger at From The Mountain"
              img="/images/rey-small.jpg"
              badge={<DarkBadge>Pro Member</DarkBadge>}
            />
          </Quote>
          <Quote>
            <p>
              The Blogging for Devs Community has become my go-to place to{' '}
              <HighlightWhite>
                validate ideas and learn from people
              </HighlightWhite>{' '}
              at the meetups. It’s the most welcoming and well-run platform I’m
              part of.
            </p>
            <Author
              color="blue"
              name="Chris Bongers"
              byline="Web Developer & Blogger at Daily Dev Tips"
              img="/images/chris-small.jpg"
              badge={<DarkBadge>Pro Member</DarkBadge>}
            />
          </Quote>
        </div>
      </TestimonialsWrapper>
    </>
  );
};

const CodeOfConduct = () => {
  return (
    <CodeOfConductWrapper>
      <PrimarySubheading color="blue">Not ready yet?</PrimarySubheading>
      <Copy style={{ textAlign: 'center' }}>
        <Link to="/" style={{ borderBottom: '1px solid' }}>
          Try the free 7-day blogging course
        </Link>
      </Copy>
    </CodeOfConductWrapper>
  );
};

const IncludedInMembership = () => {
  return (
    <>
      <div
        style={{
          maxWidth: '900px',
          margin: '0 auto',
          zIndex: 1,
          position: 'relative',
          padding: '12px'
        }}
      >
        <StripeHeroImg
          src="/images/bfd-pro-screenshot-2.png"
          alt="Blogging for Devs Pro Community"
        />
      </div>
      <LightStripe style={{ position: 'relative', top: '-100px' }}>
        <LightStripeContent>
          <PrimarySubheading color="white">
            What's included in your membership
          </PrimarySubheading>
          <SecondarySubheading>When you join us today</SecondarySubheading>
          <PlusListColumns>
            <PlusList>
              <li>
                <strong>Private community</strong> of 350 devs, bloggers,
                creators, and makers
              </li>
              <li>
                <strong>Video Vault</strong> with recordings of all past events
                and workshops
              </li>
              <li>
                <strong>Screencasts</strong> and case studies of successful
                websites
              </li>
            </PlusList>
            <PlusList>
              <li>
                <strong>Online Workshops</strong> on writing, content strategy,
                and SEO
              </li>
              <li>
                <strong>Expert AMAs</strong> with technical bloggers and
                creators in tech
              </li>
              <li>
                <strong>Self-paced Courses</strong> on SEO and writing for a
                tech audience
              </li>
            </PlusList>
            <PlusList>
              <li>
                <strong>Seasonal cohorts</strong> to help you reach your next
                milestone
              </li>
              <li>
                <strong>Feedback groups</strong> for help iterating on drafts
              </li>
              <li>
                <strong>Member deals</strong> and discounts for apps and
                relevant resources
              </li>
            </PlusList>
          </PlusListColumns>
        </LightStripeContent>
      </LightStripe>
    </>
  );
};

const FAQS = [
  {
    title: `What if I'm not "really" a developer?`,
    content: `First off, I hope you're not discounting yourself! While our community and members content is oriented for developers, we do have members who code second to their main occupation: product managers, marketers, and analytics consultants. To get the most out of the content and discussions, you should have a technical background or be in the process of learning.`
  },
  {
    title:
      "Is the community a fit for me if I'm technical, but I don't write pure tech content?",
    content: `Definitely! Apart from purely technical articles, a lot of our members blog about other topics: the product they're building (Apps, ebooks, courses, etc), product and engineering management, user experience design, and topics generally interesting to a tech-savvy audience. The key is you should either be technical yourself, or want to reach people working in tech with your blog.`
  },
  {
    title: "How about if my blog isn't my main focus?",
    content:
      "Sure, a blog can be a great way to bring people to your email list, YouTube channel, or another social profile. The important thing is that you're willing to dedicate time to making your blog work and complement what you're already doing."
  },
  {
    title: 'Can my company pay for my community membership?',
    content:
      'Absolutely. A bunch of our members had their company pay for or reimburse their membership, and we can provide you with a tax-friendly invoice.'
  },
  {
    title: 'Do you have a trial or monthly plan?',
    content: `No, there's no trial or monthly plan. Communities work best when they've got consistent, committed members who can mutually invest in each other. Blogging in particular is a long game, and takes continuous work to grow. As such, we're looking for members who're serious about taking that step together!`
  },
  {
    title: "What if I realize it's not a fit after signing up?",
    content: (
      <>
        Just write to{' '}
        <a href="mailto:monica@bloggingfordevs.com">
          monica@bloggingfordevs.com
        </a>{' '}
        within 14 days of signing up, and I'll be happy to refund you, no
        questions asked.
      </>
    )
  }
];

const Faqs = () => {
  return (
    <FaqWrapper>
      <PrimarySubheading color="blue">FAQs</PrimarySubheading>
      <SecondarySubheading>
        A question about implementation details? I'm here for ya.
      </SecondarySubheading>
      <FaqGroup faqs={FAQS} />
      <PrimarySubheading color="blue" style={{ marginTop: '48px' }}>
        Got another question?
      </PrimarySubheading>
      <Copy style={{ textAlign: 'center', marginBottom: '56px' }}>
        Email me at{' '}
        <a
          href="mailto:monica@bloggingfordevs.com"
          style={{ borderBottom: '1px solid' }}
        >
          monica@bloggingfordevs.com
        </a>{' '}
        :)
      </Copy>
    </FaqWrapper>
  );
};

const WhoItsFor = () => {
  return (
    <>
      <PrimarySubheading color="blue">
        Is this community a fit for you, too?
      </PrimarySubheading>
      <SecondarySubheading>
        The Blogging for Devs Community isn't for everyone, and that's totally
        okay. Here's how to tell if it's for you:
      </SecondarySubheading>
      <IsItAFit>
        <FitContainer>
          <PrimarySubheading color="blue" style={{ color: '#40a9ff' }}>
            <Highlight>You'll love it here if:</Highlight>
          </PrimarySubheading>
          <CheckList>
            <li>
              You're a{' '}
              <strong>
                developer, technical content creator, or indie hacker
              </strong>{' '}
              who wants to grow your audience and visibility by writing online.
            </li>
            <li>
              You're a beginner at blogging and want to{' '}
              <strong>start the right way</strong>, and get advice specific to
              your goals and situation.
            </li>
            <li>
              Or, you've already seen success creating online, but want to{' '}
              <strong>level up and strategize</strong> with people who have
              similar ambitions.
            </li>
            <li>
              You're an <strong>action-taker</strong> with a positive attitude,
              who will implement feedback you receive and put in the work!
            </li>
          </CheckList>
        </FitContainer>
        <div
          style={{
            padding: '18px'
          }}
        >
          <PrimarySubheading color="white">
            We won't jive if you are:
          </PrimarySubheading>
          <CrossList>
            <li>
              Looking for a <strong>"silver bullet"</strong> or quick solution,
              and aren't willing to dedicate time and effort to your blog and
              writing.
            </li>
            <li>
              Looking for an easy place to drop a link,{' '}
              <strong>self-promote</strong>, or sell something to other members.
            </li>
            <li>
              Are <strong>not willing to "pay it forward"</strong> and give
              other people feedback and advice, while expecting it for your own
              posts and projects.
            </li>
            <li>
              <strong>Don't really care</strong> if people read your writing!
              That's totally fine, it just means you're probably not a great
              fit.
            </li>
          </CrossList>
        </div>
      </IsItAFit>
    </>
  );
};

const Branding = styled('div')`
  display: block;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;

  span {
    font-size: 12px;
    padding: 3px 6px;
    background-color: #fff;
    color: ${(p) => p.theme.palette.primary.dark};
    display: inline-block;
    border-radius: 4px;
  }
`;

const HeroTitle = styled('h1')`
  font-size: 42px;
  line-height: 60px;
  font-weight: 900;

  @media (max-width: 800px) {
    font-size: 34px;
    line-height: 42px;
  }
`;

const HeroCopy = styled('p')`
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 40px;
`;

const HeroTestimonial = styled('p')`
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 40px;
  display: none;

  a {
    text-decoration: underline;
  }
`;

const ButtonSubtext = styled('span')`
  display: block;
  margin-top: 18px;
  font-size: 12px;
`;

const FounderMessage = () => {
  return (
    <div>
      <PrimarySubheading color="blue" style={{ marginTop: '48px' }}>
        Why we're here
      </PrimarySubheading>
      <Copy>
        When I started blogging actively in 2016, the thing that changed the
        game for me, and really helped me grow, wasn’t a course or a tool.
      </Copy>

      <Copy>
        <strong>It was community.</strong>
      </Copy>

      <Copy>
        Communities helped me learn to build successful blogs, finally
        understand SEO, and connect with experts who could give me a leg up.
      </Copy>

      <Copy>
        I’ve got no doubt I would be years behind (or even given up) reaching my
        one million reader milestone without them.
      </Copy>
      <Copy>
        <Highlight>
          That’s why I wanted to create a similar space specifically for
          developers.
        </Highlight>
      </Copy>
      <CopyBig>
        Our mission is to be a supportive place for developers like you to learn
        and help others to build an audience, attract opportunities, and grow
        your projects through blogging and creating online.
      </CopyBig>
      <Copy>
        And, most importantly, to do this in a way that’s authentic and
        genuinely helpful to others.
      </Copy>
      <Copy>
        Blogging and growing an audience is <em>so much harder</em> in
        isolation.
      </Copy>
      <Copy>That’s why we’re here!</Copy>
      <CopyWrapper>
        <Author
          color="black"
          name="Monica Lent"
          byline="BloggingforDevs.com Newsletter & Community"
          img="/images/my-face.jpg"
          badge={<Badge>Founder</Badge>}
        />
      </CopyWrapper>
    </div>
  );
};

const EventGrid = styled('div')`
  margin: 0 auto 128px;
  max-width: 1200px;
  grid-column-gap: 24px;

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(2)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const EventList = ({ events }: { events: void | Doc<IEvent>[] }) => {
  if (!events) {
    return null;
  }

  const upcomingEvents = events
    .filter((e) => firebase.firestore.Timestamp.now() < e.data.date)
    .sort((a, b) => a.data.date.toMillis() - b.data.date.toMillis())
    .slice(0, 3);

  return (
    <EventGrid>
      {upcomingEvents.map((e) => (
        <MiniEvent key={e.id} {...e} />
      ))}
    </EventGrid>
  );
};

const VideoVaultPreview = styled('div')``;

const VideoVaultGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
  }
`;

const VideoVaultWrapper = styled('div')`
  @media (max-width: 950px) {
    margin: 0 24px;
  }
`;

const VideoVault = () => {
  return (
    <VideoVaultWrapper>
      <PrimarySubheading color="blue" style={{ marginTop: '48px' }}>
        Unlock the expert AMA vault
      </PrimarySubheading>
      <SecondarySubheading>
        Get a behind-the-scenes look at how creators and developers like these
        do what they do.
      </SecondarySubheading>
      <VideoVaultGrid>
        <VideoVaultPreview>
          <img
            src="/images/ama-dave.png"
            alt="Dave Ceddia"
            style={{ width: '100%' }}
          />
          <Typography variant="h6" component="h3">
            <strong>Dave Ceddia</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Creator of Pure React and Egghead.io instructor
          </Typography>
        </VideoVaultPreview>
        <VideoVaultPreview>
          <img
            src="/images/ama-eve.png"
            alt="Eve Porcello"
            style={{ width: '100%' }}
          />
          <Typography variant="h6" component="h3">
            <strong>Eve Porcello</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Instructor at Moon Highway, Author of O'Reilly's{' '}
            <em>Learning React</em>
          </Typography>
        </VideoVaultPreview>
        <VideoVaultPreview>
          <img
            src="/images/ama-marko.png"
            alt="Marko Saric"
            style={{ width: '100%' }}
          />
          <Typography variant="h6" component="h3">
            <strong>Marko Saric</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Marketing at Plausible Analytics
          </Typography>
        </VideoVaultPreview>
        <VideoVaultPreview>
          <img
            src="/images/ama-marie.png"
            alt="Marie Poulin"
            style={{ width: '100%' }}
          />
          <Typography variant="h6" component="h3">
            <strong>Marie Poulin</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Founder of Notion Mastery
          </Typography>
        </VideoVaultPreview>
        <VideoVaultPreview>
          <img
            src="/images/ama-josh.png"
            alt="Josh Comeau"
            style={{ width: '100%' }}
          />
          <Typography variant="h6" component="h3">
            <strong>Josh Comeau</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Creator of CSS for JS Developers
          </Typography>
        </VideoVaultPreview>
        <VideoVaultPreview>
          <img
            src="/images/ama-stephanie.png"
            alt="Stephanie Morillo"
            style={{ width: '100%' }}
          />
          <Typography variant="h6" component="h3">
            <strong>Stephanie Morillo</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            DevRel Content Strategist, formerly Digital Ocean and GitHub
          </Typography>
        </VideoVaultPreview>
        <VideoVaultPreview>
          <img
            src="/images/ama-steph.png"
            alt="Steph Smith"
            style={{ width: '100%' }}
          />
          <Typography variant="h6" component="h3">
            <strong>Steph Smith</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Head of Trends.co and Director of Marketing at Hubspot
          </Typography>
        </VideoVaultPreview>
        <VideoVaultPreview>
          <img
            src="/images/ama-tyler.png"
            alt="Tyler McGinnis"
            style={{ width: '100%' }}
          />
          <Typography variant="h6" component="h3">
            <strong>Tyler McGinnis</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Founder of ui.dev, Creator of Udacity's React Nanodegree
          </Typography>
        </VideoVaultPreview>
        <VideoVaultPreview>
          <img
            src="/images/ama-martin.png"
            alt="Martin Splitt"
            style={{ width: '100%' }}
          />
          <Typography variant="h6" component="h3">
            <strong>Martin Splitt</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Developer Advocate at Google Search
          </Typography>
        </VideoVaultPreview>
      </VideoVaultGrid>
      <div
        style={{ textAlign: 'center', marginTop: '48px', marginBottom: '90px' }}
      >
        <Button
          size="large"
          color="primary"
          variant="contained"
          style={{ margin: '0 auto' }}
          onClick={() => {
            const el = document.getElementById('#signup-first');
            el?.scrollIntoView();
          }}
        >
          Unlock the expert AMA vault
        </Button>
      </div>
    </VideoVaultWrapper>
  );
};

const EventPreview = onlyOnClientSide(() => {
  const [events] = useMappedLoadingValue(useEvents(), (es) =>
    sortBy(es, (e) => -e.data.date.toMillis())
  );

  return (
    <div>
      <PrimarySubheading color="blue" style={{ marginTop: '48px' }}>
        Don't miss our next event
      </PrimarySubheading>
      <SecondarySubheading>
        Here's an idea of the kind of events we're running in the community on a
        regular basis.
      </SecondarySubheading>
      <EventList events={events} />
    </div>
  );
});

const CoursesGridWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  max-width: 1500px;
  margin: 0 auto;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
  }
`;

const CourseWrapper = styled('div')`
  max-width: 950px;
  margin: 0 auto;

  @media (max-width: 950px) {
    margin: 0 24px;
  }
`;

const CoursesAndMore = () => {
  return (
    <CourseWrapper>
      <PrimarySubheading color="blue" style={{ marginTop: '48px' }}>
        A peek inside the Video Vault
      </PrimarySubheading>
      <SecondarySubheading>
        100% included in your Blogging for Devs membership (no extra cost)
      </SecondarySubheading>
      <CoursesGridWrapper>
        <div>
          <img
            src="/images/screencasts.png"
            alt="Masterclass"
            style={{ maxWidth: '100%', marginBottom: '24px' }}
          />
          <Typography variant="h6" component="h3">
            <strong>Screencast Series</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Learn from short, focused screencasts on topics like Twitter,
            newsletters, analytics, and SEO from a tech perspective.
          </Typography>
        </div>
        <div>
          <img
            src="/images/workshops.png"
            alt="Workshops"
            style={{ maxWidth: '100%', marginBottom: '24px' }}
          />
          <Typography variant="h6" component="h3">
            <strong>Masterclass Bundle</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Get access to the 2-part masterclass bundle: On-site SEO for
            Developers and Craft Your Content Plan ($199 value).
          </Typography>
        </div>
        <div>
          <img
            src="/images/courses.png"
            alt="Masterclass"
            style={{ maxWidth: '100%', marginBottom: '24px' }}
          />
          <Typography variant="h6" component="h3">
            <strong>Video Courses</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Access all videos from <em>SEO for Devs</em> and Michael Lynch's{' '}
            <em>Hit the Front Page of Hacker News</em> (normally $59).
          </Typography>
        </div>
        <br />
        <br />
      </CoursesGridWrapper>
    </CourseWrapper>
  );
};

const IndexPage = () => {
  const [state, setState] = useState<'signup' | 'login'>('signup');
  const [open, setOpen] = useState(false);

  const handleChange = (newValue: 'signup' | 'login') => {
    setState(newValue);
  };

  return (
    <Layout>
      <SEO
        title="Join the Blogging for Devs Community"
        siteUrl="https://bloggingfordevs.com"
        description="Grow with a community of over 350 developers building their blogs and audience through writing online."
        image="/images/community-preview.png"
        socialSharingImage="/images/bfd-community-preview-image.png"
        pathname="pro/"
      />
      <ProductHuntBanner />
      <Stripe style={{ position: 'relative', top: '-40px' }}>
        <StripeContentColumns>
          <div>
            <Branding>
              Blogging for Devs <span>Pro</span>
            </Branding>
            <HeroTitle>Join the community for devs who blog.</HeroTitle>
            <HeroCopy>
              Become part of the private community for developers, indie
              hackers, freelancers, and tech creators growing their audience
              through writing online.
            </HeroCopy>
            <HeroTestimonial>
              <HighlightWhite>
                "Joining this community has been one of the best investments
                I've made for my business."
              </HighlightWhite>{' '}
              &mdash; Andrea Bizzotto,{' '}
              <em>
                Course Creator{' '}
                <a href="https://codewithandrea.com">CodeWithAndrea.com</a>
              </em>
            </HeroTestimonial>
            <FinalButton
              size="large"
              onClick={() => {
                const el = document.getElementById('#signup-first');
                el?.scrollIntoView();
              }}
            >
              Join the community
            </FinalButton>
            <ButtonSubtext>14-day money-back guarantee</ButtonSubtext>
          </div>
          <StripeHeroImgCover
            src="/images/bfd-pro-screenshot.png"
            alt="Blogging for Devs Pro Community"
          />
        </StripeContentColumns>
      </Stripe>
      <TweetWall />
      <FounderMessage />
      <IncludedInMembership />
      <CoursesAndMore />
      <VideoVault />
      <EventPreview />
      <WhoItsFor />
      <Stripe>
        <StripeContent id="#signup-first">
          <PrimarySubheading color="white">
            Become a member today
          </PrimarySubheading>
          <SecondarySubheading>
            Join over 350 developers working together to create quality,
            consistent, and impactful content online.
          </SecondarySubheading>
          <SignupWrapper>
            <SignupOrLogin
              initialState={state}
              onChangeState={handleChange}
              onLoginSuccess={() => {}}
              onSignupSuccess={() => {}}
            />
          </SignupWrapper>
        </StripeContent>
      </Stripe>
      <Faqs />
      <FinalCall>
        <img src="/images/members-blue-outline.png" />
        <div>
          <h2>We'd love for you to join us</h2>
          <p>
            Look, you and I both know that blogging on top of work and just{' '}
            <em>existing</em> in this day and age is hard enough.
          </p>
          <p>And no one can do the writing for you.</p>
          <p>
            But we can create a place that helps you make sure whatever you
            publish is as impactful as possible.
          </p>
          <p>So let’s do this together!</p>
          <FinalButton
            size="large"
            onClick={() => {
              const el = document.getElementById('#signup-first');
              el?.scrollIntoView();
            }}
          >
            Join the community
          </FinalButton>
        </div>
      </FinalCall>
      <CodeOfConduct />
      <SignupOrLoginDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        initialState="signup"
      />
    </Layout>
  );
};

const Popup = () => {
  return (
    <ExitIntentPopup maxWidth="xs" fullWidth>
      <Author
        color="black"
        name="Hey, I'm Monica!"
        byline=""
        img="/images/my-face.jpg"
        badge={null}
      />
      <br />
      <Typography variant="body1" component="p" paragraph>
        I also do a free blogging &amp; SEO course and newsletter.
      </Typography>
      <Typography variant="body1" component="p" paragraph>
        In 7 days, it takes you from idea to distribution of an excellent piece
        of content with one lesson per day.
      </Typography>
      <Typography variant="body1" component="p">
        {SUBSCRIBER_COUNT} devs have done it. I'd love you to give it a try!
      </Typography>
      <Typography variant="body1" component="p"></Typography>
      <br />
      <InnerForm stacked />
      <br />
      <TestimonialsOverflow>
        <Author
          color="black"
          name=""
          byline={
            <em>
              "Highly recommend you sign up before she realizes this should be a
              paid course."
            </em>
          }
          img="/images/joe-small.png"
          badge={null}
        />
        <br />

        <Author
          color="black"
          name=""
          byline={
            <em>
              "Much respect and even as an experienced blogger I learned a lot.
              Thank you for sharing this."
            </em>
          }
          img="/images/swyx.jpg"
          badge={null}
        />
        <br />
        <Author
          color="black"
          name=""
          byline={
            <em>
              "Not only is the newsletter super helpful, it's also super
              entertaining. It's helped me immensely, 10/10"
            </em>
          }
          img="/images/sara-small.jpg"
          badge={null}
        />
        <br />
        <Author
          color="black"
          name=""
          byline={
            <em>
              "Honestly, one of my favourite newsletters. I learned a ton
              already."
            </em>
          }
          img="/images/stefanbuck-small.jpg"
          badge={null}
        />
        <br />
        <Author
          color="black"
          name=""
          byline={
            <em>
              "I cannot believe it's free. Monica shares a lot of insights that
              clearly come from years of experience as a blogger."
            </em>
          }
          img="/images/vitalypushkar-small.jpg"
          badge={null}
        />
        <br />
        <Author
          color="black"
          name=""
          byline={
            <em>
              "Great content, adds a ton of value, always pleasant to read! One
              of the few newsletters I'm happy to see popping up in my emails."
            </em>
          }
          img="/images/thomascollart-small.jpg"
          badge={null}
        />
        <br />
        <InfoMessage>
          <small>
            Still on the fence? You can read even more reviews in our launch as{' '}
            <strong>#1 Product of the Day on Product Hunt</strong>{' '}
            <a
              href="https://www.producthunt.com/posts/blogging-for-devs"
              target="_blank"
            >
              here
            </a>
            .
          </small>
        </InfoMessage>
      </TestimonialsOverflow>
    </ExitIntentPopup>
  );
};

export default IndexPage;
